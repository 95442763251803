// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/news/v1/news.proto" (package "bpt.hall.news.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { NewsService } from "./news";
import type { UnReadCountResp } from "./news";
import type { UnReadCountReq } from "./news";
import type { MarkReadStampReq } from "./news";
import type { GetNewsResp } from "./news";
import type { GetNewsReq } from "./news";
import type { EffectiveListNewsResp } from "./news";
import type { EffectiveListNewsReq } from "./news";
import type { TopUpNewsReq } from "./news";
import type { GetNewsInnerResp } from "./news";
import type { GetNewsInnerReq } from "./news";
import type { ListNewsResp } from "./news";
import type { ListNewsReq } from "./news";
import type { EnableNewsCommentResp } from "./news";
import type { EnableNewsCommentReq } from "./news";
import type { UpdateNewsStatusReq } from "./news";
import type { DeleteNewsReq } from "./news";
import type { Void } from "../../../base/base";
import type { EditNewsReq } from "./news";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { CreateNewsResp } from "./news";
import type { CreateNewsReq } from "./news";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
/**
 * @generated from protobuf service bpt.hall.news.v1.NewsService
 */
export interface INewsServiceClient {
    /**
     * 创建新闻
     *
     * @generated from protobuf rpc: CreateNews(bpt.hall.news.v1.CreateNewsReq) returns (bpt.hall.news.v1.CreateNewsResp);
     */
    createNews(input: CreateNewsReq, options?: RpcOptions): UnaryCall<CreateNewsReq, CreateNewsResp>;
    /**
     * 更新新闻信息
     *
     * @generated from protobuf rpc: EditNews(bpt.hall.news.v1.EditNewsReq) returns (bpt.base.Void);
     */
    editNews(input: EditNewsReq, options?: RpcOptions): UnaryCall<EditNewsReq, Void>;
    /**
     * 删除新闻
     *
     * @generated from protobuf rpc: DeleteNews(bpt.hall.news.v1.DeleteNewsReq) returns (bpt.base.Void);
     */
    deleteNews(input: DeleteNewsReq, options?: RpcOptions): UnaryCall<DeleteNewsReq, Void>;
    /**
     * 发布/取消发布新闻
     *
     * @generated from protobuf rpc: UpdateNewsStatus(bpt.hall.news.v1.UpdateNewsStatusReq) returns (bpt.base.Void);
     */
    updateNewsStatus(input: UpdateNewsStatusReq, options?: RpcOptions): UnaryCall<UpdateNewsStatusReq, Void>;
    /**
     * 开启新闻评论
     *
     * @generated from protobuf rpc: EnableNewsComment(bpt.hall.news.v1.EnableNewsCommentReq) returns (bpt.hall.news.v1.EnableNewsCommentResp);
     */
    enableNewsComment(input: EnableNewsCommentReq, options?: RpcOptions): UnaryCall<EnableNewsCommentReq, EnableNewsCommentResp>;
    /**
     * 获取新闻详情列表
     *
     * @generated from protobuf rpc: ListNews(bpt.hall.news.v1.ListNewsReq) returns (bpt.hall.news.v1.ListNewsResp);
     */
    listNews(input: ListNewsReq, options?: RpcOptions): UnaryCall<ListNewsReq, ListNewsResp>;
    /**
     * 获取新闻详情
     *
     * @generated from protobuf rpc: GetNewsInner(bpt.hall.news.v1.GetNewsInnerReq) returns (bpt.hall.news.v1.GetNewsInnerResp);
     */
    getNewsInner(input: GetNewsInnerReq, options?: RpcOptions): UnaryCall<GetNewsInnerReq, GetNewsInnerResp>;
    /**
     * 设置新闻置顶
     *
     * @generated from protobuf rpc: TopUpNews(bpt.hall.news.v1.TopUpNewsReq) returns (bpt.base.Void);
     */
    topUpNews(input: TopUpNewsReq, options?: RpcOptions): UnaryCall<TopUpNewsReq, Void>;
    // ---------------------------------前台使用------------------------------------------

    /**
     * 获取新闻列表
     *
     * @generated from protobuf rpc: EffectiveListNews(bpt.hall.news.v1.EffectiveListNewsReq) returns (bpt.hall.news.v1.EffectiveListNewsResp);
     */
    effectiveListNews(input: EffectiveListNewsReq, options?: RpcOptions): UnaryCall<EffectiveListNewsReq, EffectiveListNewsResp>;
    /**
     * 获取新闻详情
     *
     * @generated from protobuf rpc: GetNews(bpt.hall.news.v1.GetNewsReq) returns (bpt.hall.news.v1.GetNewsResp);
     */
    getNews(input: GetNewsReq, options?: RpcOptions): UnaryCall<GetNewsReq, GetNewsResp>;
    /**
     * 标记已读
     *
     * @generated from protobuf rpc: MarkReadStamp(bpt.hall.news.v1.MarkReadStampReq) returns (bpt.base.Void);
     */
    markReadStamp(input: MarkReadStampReq, options?: RpcOptions): UnaryCall<MarkReadStampReq, Void>;
    /**
     * 获取未读消息数量
     *
     * @generated from protobuf rpc: UnReadCount(bpt.hall.news.v1.UnReadCountReq) returns (bpt.hall.news.v1.UnReadCountResp);
     */
    unReadCount(input: UnReadCountReq, options?: RpcOptions): UnaryCall<UnReadCountReq, UnReadCountResp>;
}
/**
 * @generated from protobuf service bpt.hall.news.v1.NewsService
 */
export class NewsServiceClient implements INewsServiceClient, ServiceInfo {
    typeName = NewsService.typeName;
    methods = NewsService.methods;
    options = NewsService.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * 创建新闻
     *
     * @generated from protobuf rpc: CreateNews(bpt.hall.news.v1.CreateNewsReq) returns (bpt.hall.news.v1.CreateNewsResp);
     */
    createNews(input: CreateNewsReq, options?: RpcOptions): UnaryCall<CreateNewsReq, CreateNewsResp> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<CreateNewsReq, CreateNewsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 更新新闻信息
     *
     * @generated from protobuf rpc: EditNews(bpt.hall.news.v1.EditNewsReq) returns (bpt.base.Void);
     */
    editNews(input: EditNewsReq, options?: RpcOptions): UnaryCall<EditNewsReq, Void> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<EditNewsReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 删除新闻
     *
     * @generated from protobuf rpc: DeleteNews(bpt.hall.news.v1.DeleteNewsReq) returns (bpt.base.Void);
     */
    deleteNews(input: DeleteNewsReq, options?: RpcOptions): UnaryCall<DeleteNewsReq, Void> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<DeleteNewsReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 发布/取消发布新闻
     *
     * @generated from protobuf rpc: UpdateNewsStatus(bpt.hall.news.v1.UpdateNewsStatusReq) returns (bpt.base.Void);
     */
    updateNewsStatus(input: UpdateNewsStatusReq, options?: RpcOptions): UnaryCall<UpdateNewsStatusReq, Void> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<UpdateNewsStatusReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 开启新闻评论
     *
     * @generated from protobuf rpc: EnableNewsComment(bpt.hall.news.v1.EnableNewsCommentReq) returns (bpt.hall.news.v1.EnableNewsCommentResp);
     */
    enableNewsComment(input: EnableNewsCommentReq, options?: RpcOptions): UnaryCall<EnableNewsCommentReq, EnableNewsCommentResp> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<EnableNewsCommentReq, EnableNewsCommentResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取新闻详情列表
     *
     * @generated from protobuf rpc: ListNews(bpt.hall.news.v1.ListNewsReq) returns (bpt.hall.news.v1.ListNewsResp);
     */
    listNews(input: ListNewsReq, options?: RpcOptions): UnaryCall<ListNewsReq, ListNewsResp> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<ListNewsReq, ListNewsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取新闻详情
     *
     * @generated from protobuf rpc: GetNewsInner(bpt.hall.news.v1.GetNewsInnerReq) returns (bpt.hall.news.v1.GetNewsInnerResp);
     */
    getNewsInner(input: GetNewsInnerReq, options?: RpcOptions): UnaryCall<GetNewsInnerReq, GetNewsInnerResp> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewsInnerReq, GetNewsInnerResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 设置新闻置顶
     *
     * @generated from protobuf rpc: TopUpNews(bpt.hall.news.v1.TopUpNewsReq) returns (bpt.base.Void);
     */
    topUpNews(input: TopUpNewsReq, options?: RpcOptions): UnaryCall<TopUpNewsReq, Void> {
        const method = this.methods[7], opt = this._transport.mergeOptions(options);
        return stackIntercept<TopUpNewsReq, Void>("unary", this._transport, method, opt, input);
    }
    // ---------------------------------前台使用------------------------------------------

    /**
     * 获取新闻列表
     *
     * @generated from protobuf rpc: EffectiveListNews(bpt.hall.news.v1.EffectiveListNewsReq) returns (bpt.hall.news.v1.EffectiveListNewsResp);
     */
    effectiveListNews(input: EffectiveListNewsReq, options?: RpcOptions): UnaryCall<EffectiveListNewsReq, EffectiveListNewsResp> {
        const method = this.methods[8], opt = this._transport.mergeOptions(options);
        return stackIntercept<EffectiveListNewsReq, EffectiveListNewsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取新闻详情
     *
     * @generated from protobuf rpc: GetNews(bpt.hall.news.v1.GetNewsReq) returns (bpt.hall.news.v1.GetNewsResp);
     */
    getNews(input: GetNewsReq, options?: RpcOptions): UnaryCall<GetNewsReq, GetNewsResp> {
        const method = this.methods[9], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetNewsReq, GetNewsResp>("unary", this._transport, method, opt, input);
    }
    /**
     * 标记已读
     *
     * @generated from protobuf rpc: MarkReadStamp(bpt.hall.news.v1.MarkReadStampReq) returns (bpt.base.Void);
     */
    markReadStamp(input: MarkReadStampReq, options?: RpcOptions): UnaryCall<MarkReadStampReq, Void> {
        const method = this.methods[10], opt = this._transport.mergeOptions(options);
        return stackIntercept<MarkReadStampReq, Void>("unary", this._transport, method, opt, input);
    }
    /**
     * 获取未读消息数量
     *
     * @generated from protobuf rpc: UnReadCount(bpt.hall.news.v1.UnReadCountReq) returns (bpt.hall.news.v1.UnReadCountResp);
     */
    unReadCount(input: UnReadCountReq, options?: RpcOptions): UnaryCall<UnReadCountReq, UnReadCountResp> {
        const method = this.methods[11], opt = this._transport.mergeOptions(options);
        return stackIntercept<UnReadCountReq, UnReadCountResp>("unary", this._transport, method, opt, input);
    }
}
