// @generated by protobuf-ts 2.9.4 with parameter generate_dependencies,keep_enum_prefix,use_proto_field_name,long_type_number,ts_nocheck
// @generated from protobuf file "bpt/hall/news/v1/news.proto" (package "bpt.hall.news.v1", syntax proto3)
// tslint:disable
// @ts-nocheck
import { Void } from "../../../base/base";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * @generated from protobuf message bpt.hall.news.v1.TopUpNewsReq
 */
export interface TopUpNewsReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: int64 topAt = 2;
     */
    topAt: number; // 赋值为0表示取消置顶
    /**
     * @generated from protobuf field: string channelID = 3;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.UnReadCountResp
 */
export interface UnReadCountResp {
    /**
     * @generated from protobuf field: int64 count = 1;
     */
    count: number;
    /**
     * @generated from protobuf field: repeated string newsIds = 2;
     */
    newsIds: string[];
}
/**
 * @generated from protobuf message bpt.hall.news.v1.GetNewsReq
 */
export interface GetNewsReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channelID = 2;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.GetNewsResp
 */
export interface GetNewsResp {
    /**
     * @generated from protobuf field: bpt.hall.news.v1.SimpleNews news = 1;
     */
    news?: SimpleNews;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.GetNewsInnerReq
 */
export interface GetNewsInnerReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channelID = 2;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.GetNewsInnerResp
 */
export interface GetNewsInnerResp {
    /**
     * @generated from protobuf field: bpt.hall.news.v1.News news = 1;
     */
    news?: News;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.ListNewsReq
 */
export interface ListNewsReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 3;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsStatus status = 4;
     */
    status: NewsStatus;
    /**
     * @generated from protobuf field: int64 commentTopicId = 5;
     */
    commentTopicId: number;
    /**
     * @generated from protobuf field: string title = 6;
     */
    title: string;
    /**
     * @generated from protobuf field: int64 effectiveAtFrom = 7;
     */
    effectiveAtFrom: number;
    /**
     * @generated from protobuf field: int64 effectiveAtTo = 8;
     */
    effectiveAtTo: number;
    /**
     * @generated from protobuf field: int64 originCreateTimeFrom = 9;
     */
    originCreateTimeFrom: number;
    /**
     * @generated from protobuf field: int64 originCreateTimeTo = 10;
     */
    originCreateTimeTo: number;
    /**
     * @generated from protobuf field: string origin = 11;
     */
    origin: string;
    /**
     * @generated from protobuf field: bool sortByTopAt = 12;
     */
    sortByTopAt: boolean;
    /**
     * @generated from protobuf field: string channelID = 13;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.ListNewsResp
 */
export interface ListNewsResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.News newsList = 1;
     */
    newsList: News[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.EffectiveListNewsReq
 */
export interface EffectiveListNewsReq {
    /**
     * @generated from protobuf field: int64 page = 1;
     */
    page: number;
    /**
     * @generated from protobuf field: int64 pageSize = 2;
     */
    pageSize: number;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 3;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: bool sortByTopAt = 4;
     */
    sortByTopAt: boolean;
    /**
     * @generated from protobuf field: string channelID = 5;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.EffectiveListNewsResp
 */
export interface EffectiveListNewsResp {
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.SimpleNews newsList = 1;
     */
    newsList: SimpleNews[];
    /**
     * @generated from protobuf field: int64 total = 2;
     */
    total: number;
    /**
     * @generated from protobuf field: int64 unreadNewsCount = 3;
     */
    unreadNewsCount: number;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.UpdateNewsStatusReq
 */
export interface UpdateNewsStatusReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsStatus status = 2;
     */
    status: NewsStatus;
    /**
     * @generated from protobuf field: string operator = 3;
     */
    operator: string;
    /**
     * @generated from protobuf field: bool isEffectiveNow = 4;
     */
    isEffectiveNow: boolean;
    /**
     * @generated from protobuf field: int64 effectiveAt = 5;
     */
    effectiveAt: number;
    /**
     * @generated from protobuf field: string channelID = 6;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.DeleteNewsReq
 */
export interface DeleteNewsReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string operator = 2;
     */
    operator: string;
    /**
     * @generated from protobuf field: string channelID = 3;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.MainVideosStruct
 */
export interface MainVideosStruct {
    /**
     * @generated from protobuf field: string url = 1;
     */
    url: string;
    /**
     * @generated from protobuf field: string poster = 2;
     */
    poster: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.CreateNewsReq
 */
export interface CreateNewsReq {
    /**
     * @generated from protobuf field: string title = 1;
     */
    title: string;
    /**
     * @generated from protobuf field: string subTitle = 2;
     */
    subTitle: string;
    /**
     * @generated from protobuf field: string origin = 3;
     */
    origin: string;
    /**
     * @generated from protobuf field: string originRoute = 4;
     */
    originRoute: string;
    /**
     * @generated from protobuf field: int64 originCreateTime = 5;
     */
    originCreateTime: number;
    /**
     * @generated from protobuf field: string mainImg = 6;
     */
    mainImg: string;
    /**
     * @generated from protobuf field: string richText = 7;
     */
    richText: string;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 9;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: int64 effectiveAt = 10;
     */
    effectiveAt: number;
    /**
     * @generated from protobuf field: string operator = 11;
     */
    operator: string;
    /**
     * @generated from protobuf field: map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];
     */
    i18nTitle: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];
     */
    i18nRichTextUrl: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18;
     */
    mainVideos: MainVideosStruct[];
    /**
     * @generated from protobuf field: repeated string coverImages = 19;
     */
    coverImages: string[];
    /**
     * @generated from protobuf field: string channelID = 20;
     */
    channelID: string;
    /**
     * @generated from protobuf field: string abstract = 21;
     */
    abstract: string;
    /**
     * @generated from protobuf field: string jumpLink = 22;
     */
    jumpLink: string;
    /**
     * @generated from protobuf field: string pcBannerImage = 23;
     */
    pcBannerImage: string;
    /**
     * @generated from protobuf field: string mobileBannerImage = 24;
     */
    mobileBannerImage: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.CreateNewsResp
 */
export interface CreateNewsResp {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.EditNewsReq
 */
export interface EditNewsReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string subTitle = 3;
     */
    subTitle: string;
    /**
     * @generated from protobuf field: string origin = 4;
     */
    origin: string;
    /**
     * @generated from protobuf field: string originRoute = 5;
     */
    originRoute: string;
    /**
     * @generated from protobuf field: int64 originCreateTime = 6;
     */
    originCreateTime: number;
    /**
     * @generated from protobuf field: string mainImg = 7;
     */
    mainImg: string;
    /**
     * @generated from protobuf field: string richText = 8;
     */
    richText: string;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 9;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: int64 effectiveAt = 10;
     */
    effectiveAt: number;
    /**
     * @generated from protobuf field: string operator = 11;
     */
    operator: string;
    /**
     * @generated from protobuf field: map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];
     */
    i18nTitle: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];
     */
    i18nRichTextUrl: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18;
     */
    mainVideos: MainVideosStruct[];
    /**
     * @generated from protobuf field: repeated string coverImages = 19;
     */
    coverImages: string[];
    /**
     * @generated from protobuf field: string channelID = 20;
     */
    channelID: string;
    /**
     * @generated from protobuf field: string abstract = 21;
     */
    abstract: string;
    /**
     * @generated from protobuf field: string jumpLink = 22;
     */
    jumpLink: string;
    /**
     * @generated from protobuf field: string pcBannerImage = 23;
     */
    pcBannerImage: string;
    /**
     * @generated from protobuf field: string mobileBannerImage = 24;
     */
    mobileBannerImage: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.SimpleNews
 */
export interface SimpleNews {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string subTitle = 3;
     */
    subTitle: string;
    /**
     * @generated from protobuf field: string origin = 4;
     */
    origin: string;
    /**
     * @generated from protobuf field: string originRoute = 5;
     */
    originRoute: string;
    /**
     * @generated from protobuf field: int64 originCreateTime = 6;
     */
    originCreateTime: number;
    /**
     * @generated from protobuf field: string mainImg = 7;
     */
    mainImg: string;
    /**
     *    string richText = 8;
     *
     * @generated from protobuf field: bpt.hall.news.v1.NewsStatus status = 9;
     */
    status: NewsStatus;
    /**
     * @generated from protobuf field: int64 createdAt = 10;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 updatedAt = 11;
     */
    updatedAt: number;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 12;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: int64 effectiveAt = 13;
     */
    effectiveAt: number;
    /**
     * @generated from protobuf field: string i18nTitle = 14 [json_name = "i18nTitle"];
     */
    i18nTitle: string;
    /**
     * @generated from protobuf field: string i18nRichTextUrl = 15 [json_name = "i18nRichTextUrl"];
     */
    i18nRichTextUrl: string;
    /**
     * @generated from protobuf field: int64 topAt = 16;
     */
    topAt: number;
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 17;
     */
    mainVideos: MainVideosStruct[];
    /**
     * @generated from protobuf field: repeated string coverImages = 18;
     */
    coverImages: string[];
    /**
     * @generated from protobuf field: int64 commentTopicId = 19;
     */
    commentTopicId: number;
    /**
     * @generated from protobuf field: string abstract = 20;
     */
    abstract: string;
    /**
     * @generated from protobuf field: string jumpLink = 21;
     */
    jumpLink: string;
    /**
     * @generated from protobuf field: string pcBannerImage = 23;
     */
    pcBannerImage: string;
    /**
     * @generated from protobuf field: string mobileBannerImage = 24;
     */
    mobileBannerImage: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.News
 */
export interface News {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string title = 2;
     */
    title: string;
    /**
     * @generated from protobuf field: string subTitle = 3;
     */
    subTitle: string;
    /**
     * @generated from protobuf field: string origin = 4;
     */
    origin: string;
    /**
     * @generated from protobuf field: string originRoute = 5;
     */
    originRoute: string;
    /**
     * @generated from protobuf field: int64 originCreateTime = 6;
     */
    originCreateTime: number;
    /**
     * @generated from protobuf field: string mainImg = 7;
     */
    mainImg: string;
    /**
     * @generated from protobuf field: string richText = 8;
     */
    richText: string;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsStatus status = 9;
     */
    status: NewsStatus;
    /**
     * @generated from protobuf field: int64 createdAt = 10;
     */
    createdAt: number;
    /**
     * @generated from protobuf field: int64 updatedAt = 11;
     */
    updatedAt: number;
    /**
     * @generated from protobuf field: bpt.hall.news.v1.NewsCategory category = 12;
     */
    category: NewsCategory;
    /**
     * @generated from protobuf field: int64 effectiveAt = 13;
     */
    effectiveAt: number;
    /**
     * @generated from protobuf field: int64 commentTopicId = 14;
     */
    commentTopicId: number;
    /**
     * 置顶时间
     *
     * @generated from protobuf field: int64 topAt = 15;
     */
    topAt: number;
    /**
     * @generated from protobuf field: map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];
     */
    i18nTitle: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];
     */
    i18nRichTextUrl: {
        [key: string]: string;
    };
    /**
     * @generated from protobuf field: repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18;
     */
    mainVideos: MainVideosStruct[];
    /**
     * @generated from protobuf field: repeated string coverImages = 19;
     */
    coverImages: string[];
    /**
     * @generated from protobuf field: string abstract = 20;
     */
    abstract: string;
    /**
     * @generated from protobuf field: string jumpLink = 21;
     */
    jumpLink: string;
    /**
     * @generated from protobuf field: string pcBannerImage = 23;
     */
    pcBannerImage: string;
    /**
     * @generated from protobuf field: string mobileBannerImage = 24;
     */
    mobileBannerImage: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.EnableNewsCommentReq
 */
export interface EnableNewsCommentReq {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string channelID = 2;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.EnableNewsCommentResp
 */
export interface EnableNewsCommentResp {
    /**
     * @generated from protobuf field: int64 commentTopicId = 1;
     */
    commentTopicId: number;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.MarkReadStampReq
 */
export interface MarkReadStampReq {
    /**
     * @generated from protobuf field: string channelID = 1;
     */
    channelID: string;
}
/**
 * @generated from protobuf message bpt.hall.news.v1.UnReadCountReq
 */
export interface UnReadCountReq {
    /**
     * @generated from protobuf field: string channelID = 1;
     */
    channelID: string;
}
/**
 * @generated from protobuf enum bpt.hall.news.v1.NewsStatus
 */
export enum NewsStatus {
    /**
     * @generated from protobuf enum value: NewsStatusAll = 0;
     */
    NewsStatusAll = 0,
    /**
     * @generated from protobuf enum value: NewsStatusDraft = 1;
     */
    NewsStatusDraft = 1,
    /**
     * @generated from protobuf enum value: NewsStatusPublished = 2;
     */
    NewsStatusPublished = 2,
    /**
     * @generated from protobuf enum value: NewsStatusRecall = 3;
     */
    NewsStatusRecall = 3,
    /**
     * @generated from protobuf enum value: NewsStatusDeleted = 4;
     */
    NewsStatusDeleted = 4
}
/**
 * @generated from protobuf enum bpt.hall.news.v1.NewsCategory
 */
export enum NewsCategory {
    /**
     * @generated from protobuf enum value: NewsCategoryAll = 0;
     */
    NewsCategoryAll = 0,
    /**
     * @generated from protobuf enum value: NewsCategoryNews = 1;
     */
    NewsCategoryNews = 1,
    /**
     * @generated from protobuf enum value: NewsCategoryStory = 2;
     */
    NewsCategoryStory = 2,
    /**
     * @generated from protobuf enum value: NewsCategoryProduct = 3;
     */
    NewsCategoryProduct = 3,
    /**
     * @generated from protobuf enum value: NewsCategoryEvents = 4;
     */
    NewsCategoryEvents = 4,
    /**
     * @generated from protobuf enum value: NewsCategoryOther = 5;
     */
    NewsCategoryOther = 5
}
// @generated message type with reflection information, may provide speed optimized methods
class TopUpNewsReq$Type extends MessageType<TopUpNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.TopUpNewsReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "topAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TopUpNewsReq>): TopUpNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.topAt = 0;
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<TopUpNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TopUpNewsReq): TopUpNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* int64 topAt */ 2:
                    message.topAt = reader.int64().toNumber();
                    break;
                case /* string channelID */ 3:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TopUpNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* int64 topAt = 2; */
        if (message.topAt !== 0)
            writer.tag(2, WireType.Varint).int64(message.topAt);
        /* string channelID = 3; */
        if (message.channelID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.TopUpNewsReq
 */
export const TopUpNewsReq = new TopUpNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnReadCountResp$Type extends MessageType<UnReadCountResp> {
    constructor() {
        super("bpt.hall.news.v1.UnReadCountResp", [
            { no: 1, name: "count", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "newsIds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UnReadCountResp>): UnReadCountResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.count = 0;
        message.newsIds = [];
        if (value !== undefined)
            reflectionMergePartial<UnReadCountResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnReadCountResp): UnReadCountResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 count */ 1:
                    message.count = reader.int64().toNumber();
                    break;
                case /* repeated string newsIds */ 2:
                    message.newsIds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnReadCountResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 count = 1; */
        if (message.count !== 0)
            writer.tag(1, WireType.Varint).int64(message.count);
        /* repeated string newsIds = 2; */
        for (let i = 0; i < message.newsIds.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.newsIds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.UnReadCountResp
 */
export const UnReadCountResp = new UnReadCountResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewsReq$Type extends MessageType<GetNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.GetNewsReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewsReq>): GetNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<GetNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewsReq): GetNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channelID */ 2:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channelID = 2; */
        if (message.channelID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.GetNewsReq
 */
export const GetNewsReq = new GetNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewsResp$Type extends MessageType<GetNewsResp> {
    constructor() {
        super("bpt.hall.news.v1.GetNewsResp", [
            { no: 1, name: "news", kind: "message", T: () => SimpleNews }
        ]);
    }
    create(value?: PartialMessage<GetNewsResp>): GetNewsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNewsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewsResp): GetNewsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.news.v1.SimpleNews news */ 1:
                    message.news = SimpleNews.internalBinaryRead(reader, reader.uint32(), options, message.news);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.news.v1.SimpleNews news = 1; */
        if (message.news)
            SimpleNews.internalBinaryWrite(message.news, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.GetNewsResp
 */
export const GetNewsResp = new GetNewsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewsInnerReq$Type extends MessageType<GetNewsInnerReq> {
    constructor() {
        super("bpt.hall.news.v1.GetNewsInnerReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetNewsInnerReq>): GetNewsInnerReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<GetNewsInnerReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewsInnerReq): GetNewsInnerReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channelID */ 2:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewsInnerReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channelID = 2; */
        if (message.channelID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.GetNewsInnerReq
 */
export const GetNewsInnerReq = new GetNewsInnerReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNewsInnerResp$Type extends MessageType<GetNewsInnerResp> {
    constructor() {
        super("bpt.hall.news.v1.GetNewsInnerResp", [
            { no: 1, name: "news", kind: "message", T: () => News }
        ]);
    }
    create(value?: PartialMessage<GetNewsInnerResp>): GetNewsInnerResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNewsInnerResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNewsInnerResp): GetNewsInnerResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bpt.hall.news.v1.News news */ 1:
                    message.news = News.internalBinaryRead(reader, reader.uint32(), options, message.news);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNewsInnerResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bpt.hall.news.v1.News news = 1; */
        if (message.news)
            News.internalBinaryWrite(message.news, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.GetNewsInnerResp
 */
export const GetNewsInnerResp = new GetNewsInnerResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNewsReq$Type extends MessageType<ListNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.ListNewsReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 4, name: "status", kind: "enum", T: () => ["bpt.hall.news.v1.NewsStatus", NewsStatus] },
            { no: 5, name: "commentTopicId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "effectiveAtFrom", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "effectiveAtTo", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "originCreateTimeFrom", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "originCreateTimeTo", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 12, name: "sortByTopAt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 13, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ListNewsReq>): ListNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.category = 0;
        message.status = 0;
        message.commentTopicId = 0;
        message.title = "";
        message.effectiveAtFrom = 0;
        message.effectiveAtTo = 0;
        message.originCreateTimeFrom = 0;
        message.originCreateTimeTo = 0;
        message.origin = "";
        message.sortByTopAt = false;
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<ListNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNewsReq): ListNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 3:
                    message.category = reader.int32();
                    break;
                case /* bpt.hall.news.v1.NewsStatus status */ 4:
                    message.status = reader.int32();
                    break;
                case /* int64 commentTopicId */ 5:
                    message.commentTopicId = reader.int64().toNumber();
                    break;
                case /* string title */ 6:
                    message.title = reader.string();
                    break;
                case /* int64 effectiveAtFrom */ 7:
                    message.effectiveAtFrom = reader.int64().toNumber();
                    break;
                case /* int64 effectiveAtTo */ 8:
                    message.effectiveAtTo = reader.int64().toNumber();
                    break;
                case /* int64 originCreateTimeFrom */ 9:
                    message.originCreateTimeFrom = reader.int64().toNumber();
                    break;
                case /* int64 originCreateTimeTo */ 10:
                    message.originCreateTimeTo = reader.int64().toNumber();
                    break;
                case /* string origin */ 11:
                    message.origin = reader.string();
                    break;
                case /* bool sortByTopAt */ 12:
                    message.sortByTopAt = reader.bool();
                    break;
                case /* string channelID */ 13:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.hall.news.v1.NewsCategory category = 3; */
        if (message.category !== 0)
            writer.tag(3, WireType.Varint).int32(message.category);
        /* bpt.hall.news.v1.NewsStatus status = 4; */
        if (message.status !== 0)
            writer.tag(4, WireType.Varint).int32(message.status);
        /* int64 commentTopicId = 5; */
        if (message.commentTopicId !== 0)
            writer.tag(5, WireType.Varint).int64(message.commentTopicId);
        /* string title = 6; */
        if (message.title !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.title);
        /* int64 effectiveAtFrom = 7; */
        if (message.effectiveAtFrom !== 0)
            writer.tag(7, WireType.Varint).int64(message.effectiveAtFrom);
        /* int64 effectiveAtTo = 8; */
        if (message.effectiveAtTo !== 0)
            writer.tag(8, WireType.Varint).int64(message.effectiveAtTo);
        /* int64 originCreateTimeFrom = 9; */
        if (message.originCreateTimeFrom !== 0)
            writer.tag(9, WireType.Varint).int64(message.originCreateTimeFrom);
        /* int64 originCreateTimeTo = 10; */
        if (message.originCreateTimeTo !== 0)
            writer.tag(10, WireType.Varint).int64(message.originCreateTimeTo);
        /* string origin = 11; */
        if (message.origin !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.origin);
        /* bool sortByTopAt = 12; */
        if (message.sortByTopAt !== false)
            writer.tag(12, WireType.Varint).bool(message.sortByTopAt);
        /* string channelID = 13; */
        if (message.channelID !== "")
            writer.tag(13, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.ListNewsReq
 */
export const ListNewsReq = new ListNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ListNewsResp$Type extends MessageType<ListNewsResp> {
    constructor() {
        super("bpt.hall.news.v1.ListNewsResp", [
            { no: 1, name: "newsList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => News },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<ListNewsResp>): ListNewsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.newsList = [];
        message.total = 0;
        if (value !== undefined)
            reflectionMergePartial<ListNewsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ListNewsResp): ListNewsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.news.v1.News newsList */ 1:
                    message.newsList.push(News.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ListNewsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.news.v1.News newsList = 1; */
        for (let i = 0; i < message.newsList.length; i++)
            News.internalBinaryWrite(message.newsList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.ListNewsResp
 */
export const ListNewsResp = new ListNewsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EffectiveListNewsReq$Type extends MessageType<EffectiveListNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.EffectiveListNewsReq", [
            { no: 1, name: "page", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "pageSize", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 4, name: "sortByTopAt", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EffectiveListNewsReq>): EffectiveListNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.page = 0;
        message.pageSize = 0;
        message.category = 0;
        message.sortByTopAt = false;
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<EffectiveListNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EffectiveListNewsReq): EffectiveListNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 page */ 1:
                    message.page = reader.int64().toNumber();
                    break;
                case /* int64 pageSize */ 2:
                    message.pageSize = reader.int64().toNumber();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 3:
                    message.category = reader.int32();
                    break;
                case /* bool sortByTopAt */ 4:
                    message.sortByTopAt = reader.bool();
                    break;
                case /* string channelID */ 5:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EffectiveListNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 page = 1; */
        if (message.page !== 0)
            writer.tag(1, WireType.Varint).int64(message.page);
        /* int64 pageSize = 2; */
        if (message.pageSize !== 0)
            writer.tag(2, WireType.Varint).int64(message.pageSize);
        /* bpt.hall.news.v1.NewsCategory category = 3; */
        if (message.category !== 0)
            writer.tag(3, WireType.Varint).int32(message.category);
        /* bool sortByTopAt = 4; */
        if (message.sortByTopAt !== false)
            writer.tag(4, WireType.Varint).bool(message.sortByTopAt);
        /* string channelID = 5; */
        if (message.channelID !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.EffectiveListNewsReq
 */
export const EffectiveListNewsReq = new EffectiveListNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EffectiveListNewsResp$Type extends MessageType<EffectiveListNewsResp> {
    constructor() {
        super("bpt.hall.news.v1.EffectiveListNewsResp", [
            { no: 1, name: "newsList", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => SimpleNews },
            { no: 2, name: "total", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "unreadNewsCount", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<EffectiveListNewsResp>): EffectiveListNewsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.newsList = [];
        message.total = 0;
        message.unreadNewsCount = 0;
        if (value !== undefined)
            reflectionMergePartial<EffectiveListNewsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EffectiveListNewsResp): EffectiveListNewsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated bpt.hall.news.v1.SimpleNews newsList */ 1:
                    message.newsList.push(SimpleNews.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* int64 total */ 2:
                    message.total = reader.int64().toNumber();
                    break;
                case /* int64 unreadNewsCount */ 3:
                    message.unreadNewsCount = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EffectiveListNewsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated bpt.hall.news.v1.SimpleNews newsList = 1; */
        for (let i = 0; i < message.newsList.length; i++)
            SimpleNews.internalBinaryWrite(message.newsList[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* int64 total = 2; */
        if (message.total !== 0)
            writer.tag(2, WireType.Varint).int64(message.total);
        /* int64 unreadNewsCount = 3; */
        if (message.unreadNewsCount !== 0)
            writer.tag(3, WireType.Varint).int64(message.unreadNewsCount);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.EffectiveListNewsResp
 */
export const EffectiveListNewsResp = new EffectiveListNewsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UpdateNewsStatusReq$Type extends MessageType<UpdateNewsStatusReq> {
    constructor() {
        super("bpt.hall.news.v1.UpdateNewsStatusReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "status", kind: "enum", T: () => ["bpt.hall.news.v1.NewsStatus", NewsStatus] },
            { no: 3, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "isEffectiveNow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "effectiveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UpdateNewsStatusReq>): UpdateNewsStatusReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.status = 0;
        message.operator = "";
        message.isEffectiveNow = false;
        message.effectiveAt = 0;
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<UpdateNewsStatusReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UpdateNewsStatusReq): UpdateNewsStatusReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* bpt.hall.news.v1.NewsStatus status */ 2:
                    message.status = reader.int32();
                    break;
                case /* string operator */ 3:
                    message.operator = reader.string();
                    break;
                case /* bool isEffectiveNow */ 4:
                    message.isEffectiveNow = reader.bool();
                    break;
                case /* int64 effectiveAt */ 5:
                    message.effectiveAt = reader.int64().toNumber();
                    break;
                case /* string channelID */ 6:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UpdateNewsStatusReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* bpt.hall.news.v1.NewsStatus status = 2; */
        if (message.status !== 0)
            writer.tag(2, WireType.Varint).int32(message.status);
        /* string operator = 3; */
        if (message.operator !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.operator);
        /* bool isEffectiveNow = 4; */
        if (message.isEffectiveNow !== false)
            writer.tag(4, WireType.Varint).bool(message.isEffectiveNow);
        /* int64 effectiveAt = 5; */
        if (message.effectiveAt !== 0)
            writer.tag(5, WireType.Varint).int64(message.effectiveAt);
        /* string channelID = 6; */
        if (message.channelID !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.UpdateNewsStatusReq
 */
export const UpdateNewsStatusReq = new UpdateNewsStatusReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DeleteNewsReq$Type extends MessageType<DeleteNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.DeleteNewsReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DeleteNewsReq>): DeleteNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.operator = "";
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<DeleteNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DeleteNewsReq): DeleteNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string operator */ 2:
                    message.operator = reader.string();
                    break;
                case /* string channelID */ 3:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DeleteNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string operator = 2; */
        if (message.operator !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.operator);
        /* string channelID = 3; */
        if (message.channelID !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.DeleteNewsReq
 */
export const DeleteNewsReq = new DeleteNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MainVideosStruct$Type extends MessageType<MainVideosStruct> {
    constructor() {
        super("bpt.hall.news.v1.MainVideosStruct", [
            { no: 1, name: "url", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "poster", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MainVideosStruct>): MainVideosStruct {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.url = "";
        message.poster = "";
        if (value !== undefined)
            reflectionMergePartial<MainVideosStruct>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MainVideosStruct): MainVideosStruct {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string url */ 1:
                    message.url = reader.string();
                    break;
                case /* string poster */ 2:
                    message.poster = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MainVideosStruct, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string url = 1; */
        if (message.url !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.url);
        /* string poster = 2; */
        if (message.poster !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.poster);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.MainVideosStruct
 */
export const MainVideosStruct = new MainVideosStruct$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNewsReq$Type extends MessageType<CreateNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.CreateNewsReq", [
            { no: 1, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "subTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "originRoute", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "originCreateTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 6, name: "mainImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 7, name: "richText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 10, name: "effectiveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "i18nTitle", kind: "map", localName: "i18nTitle", jsonName: "i18nTitle", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 17, name: "i18nRichTextUrl", kind: "map", localName: "i18nRichTextUrl", jsonName: "i18nRichTextUrl", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 18, name: "mainVideos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MainVideosStruct },
            { no: 19, name: "coverImages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "abstract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "jumpLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "pcBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "mobileBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateNewsReq>): CreateNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.title = "";
        message.subTitle = "";
        message.origin = "";
        message.originRoute = "";
        message.originCreateTime = 0;
        message.mainImg = "";
        message.richText = "";
        message.category = 0;
        message.effectiveAt = 0;
        message.operator = "";
        message.i18nTitle = {};
        message.i18nRichTextUrl = {};
        message.mainVideos = [];
        message.coverImages = [];
        message.channelID = "";
        message.abstract = "";
        message.jumpLink = "";
        message.pcBannerImage = "";
        message.mobileBannerImage = "";
        if (value !== undefined)
            reflectionMergePartial<CreateNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateNewsReq): CreateNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string title */ 1:
                    message.title = reader.string();
                    break;
                case /* string subTitle */ 2:
                    message.subTitle = reader.string();
                    break;
                case /* string origin */ 3:
                    message.origin = reader.string();
                    break;
                case /* string originRoute */ 4:
                    message.originRoute = reader.string();
                    break;
                case /* int64 originCreateTime */ 5:
                    message.originCreateTime = reader.int64().toNumber();
                    break;
                case /* string mainImg */ 6:
                    message.mainImg = reader.string();
                    break;
                case /* string richText */ 7:
                    message.richText = reader.string();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 9:
                    message.category = reader.int32();
                    break;
                case /* int64 effectiveAt */ 10:
                    message.effectiveAt = reader.int64().toNumber();
                    break;
                case /* string operator */ 11:
                    message.operator = reader.string();
                    break;
                case /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];*/ 16:
                    this.binaryReadMap16(message.i18nTitle, reader, options);
                    break;
                case /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];*/ 17:
                    this.binaryReadMap17(message.i18nRichTextUrl, reader, options);
                    break;
                case /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos */ 18:
                    message.mainVideos.push(MainVideosStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string coverImages */ 19:
                    message.coverImages.push(reader.string());
                    break;
                case /* string channelID */ 20:
                    message.channelID = reader.string();
                    break;
                case /* string abstract */ 21:
                    message.abstract = reader.string();
                    break;
                case /* string jumpLink */ 22:
                    message.jumpLink = reader.string();
                    break;
                case /* string pcBannerImage */ 23:
                    message.pcBannerImage = reader.string();
                    break;
                case /* string mobileBannerImage */ 24:
                    message.mobileBannerImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap16(map: CreateNewsReq["i18nTitle"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateNewsReq["i18nTitle"] | undefined, val: CreateNewsReq["i18nTitle"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.CreateNewsReq.i18nTitle");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap17(map: CreateNewsReq["i18nRichTextUrl"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof CreateNewsReq["i18nRichTextUrl"] | undefined, val: CreateNewsReq["i18nRichTextUrl"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.CreateNewsReq.i18nRichTextUrl");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: CreateNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string title = 1; */
        if (message.title !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.title);
        /* string subTitle = 2; */
        if (message.subTitle !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.subTitle);
        /* string origin = 3; */
        if (message.origin !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.origin);
        /* string originRoute = 4; */
        if (message.originRoute !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.originRoute);
        /* int64 originCreateTime = 5; */
        if (message.originCreateTime !== 0)
            writer.tag(5, WireType.Varint).int64(message.originCreateTime);
        /* string mainImg = 6; */
        if (message.mainImg !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.mainImg);
        /* string richText = 7; */
        if (message.richText !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.richText);
        /* bpt.hall.news.v1.NewsCategory category = 9; */
        if (message.category !== 0)
            writer.tag(9, WireType.Varint).int32(message.category);
        /* int64 effectiveAt = 10; */
        if (message.effectiveAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.effectiveAt);
        /* string operator = 11; */
        if (message.operator !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.operator);
        /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"]; */
        for (let k of globalThis.Object.keys(message.i18nTitle))
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nTitle[k]).join();
        /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"]; */
        for (let k of globalThis.Object.keys(message.i18nRichTextUrl))
            writer.tag(17, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nRichTextUrl[k]).join();
        /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18; */
        for (let i = 0; i < message.mainVideos.length; i++)
            MainVideosStruct.internalBinaryWrite(message.mainVideos[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated string coverImages = 19; */
        for (let i = 0; i < message.coverImages.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.coverImages[i]);
        /* string channelID = 20; */
        if (message.channelID !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.channelID);
        /* string abstract = 21; */
        if (message.abstract !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.abstract);
        /* string jumpLink = 22; */
        if (message.jumpLink !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.jumpLink);
        /* string pcBannerImage = 23; */
        if (message.pcBannerImage !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.pcBannerImage);
        /* string mobileBannerImage = 24; */
        if (message.mobileBannerImage !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.mobileBannerImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.CreateNewsReq
 */
export const CreateNewsReq = new CreateNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class CreateNewsResp$Type extends MessageType<CreateNewsResp> {
    constructor() {
        super("bpt.hall.news.v1.CreateNewsResp", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<CreateNewsResp>): CreateNewsResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        if (value !== undefined)
            reflectionMergePartial<CreateNewsResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: CreateNewsResp): CreateNewsResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: CreateNewsResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.CreateNewsResp
 */
export const CreateNewsResp = new CreateNewsResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EditNewsReq$Type extends MessageType<EditNewsReq> {
    constructor() {
        super("bpt.hall.news.v1.EditNewsReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "originRoute", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "originCreateTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "mainImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "richText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 10, name: "effectiveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "operator", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "i18nTitle", kind: "map", localName: "i18nTitle", jsonName: "i18nTitle", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 17, name: "i18nRichTextUrl", kind: "map", localName: "i18nRichTextUrl", jsonName: "i18nRichTextUrl", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 18, name: "mainVideos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MainVideosStruct },
            { no: 19, name: "coverImages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "abstract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 22, name: "jumpLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "pcBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "mobileBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EditNewsReq>): EditNewsReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.title = "";
        message.subTitle = "";
        message.origin = "";
        message.originRoute = "";
        message.originCreateTime = 0;
        message.mainImg = "";
        message.richText = "";
        message.category = 0;
        message.effectiveAt = 0;
        message.operator = "";
        message.i18nTitle = {};
        message.i18nRichTextUrl = {};
        message.mainVideos = [];
        message.coverImages = [];
        message.channelID = "";
        message.abstract = "";
        message.jumpLink = "";
        message.pcBannerImage = "";
        message.mobileBannerImage = "";
        if (value !== undefined)
            reflectionMergePartial<EditNewsReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EditNewsReq): EditNewsReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subTitle */ 3:
                    message.subTitle = reader.string();
                    break;
                case /* string origin */ 4:
                    message.origin = reader.string();
                    break;
                case /* string originRoute */ 5:
                    message.originRoute = reader.string();
                    break;
                case /* int64 originCreateTime */ 6:
                    message.originCreateTime = reader.int64().toNumber();
                    break;
                case /* string mainImg */ 7:
                    message.mainImg = reader.string();
                    break;
                case /* string richText */ 8:
                    message.richText = reader.string();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 9:
                    message.category = reader.int32();
                    break;
                case /* int64 effectiveAt */ 10:
                    message.effectiveAt = reader.int64().toNumber();
                    break;
                case /* string operator */ 11:
                    message.operator = reader.string();
                    break;
                case /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];*/ 16:
                    this.binaryReadMap16(message.i18nTitle, reader, options);
                    break;
                case /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];*/ 17:
                    this.binaryReadMap17(message.i18nRichTextUrl, reader, options);
                    break;
                case /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos */ 18:
                    message.mainVideos.push(MainVideosStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string coverImages */ 19:
                    message.coverImages.push(reader.string());
                    break;
                case /* string channelID */ 20:
                    message.channelID = reader.string();
                    break;
                case /* string abstract */ 21:
                    message.abstract = reader.string();
                    break;
                case /* string jumpLink */ 22:
                    message.jumpLink = reader.string();
                    break;
                case /* string pcBannerImage */ 23:
                    message.pcBannerImage = reader.string();
                    break;
                case /* string mobileBannerImage */ 24:
                    message.mobileBannerImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap16(map: EditNewsReq["i18nTitle"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof EditNewsReq["i18nTitle"] | undefined, val: EditNewsReq["i18nTitle"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.EditNewsReq.i18nTitle");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap17(map: EditNewsReq["i18nRichTextUrl"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof EditNewsReq["i18nRichTextUrl"] | undefined, val: EditNewsReq["i18nRichTextUrl"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.EditNewsReq.i18nRichTextUrl");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: EditNewsReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subTitle = 3; */
        if (message.subTitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subTitle);
        /* string origin = 4; */
        if (message.origin !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.origin);
        /* string originRoute = 5; */
        if (message.originRoute !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.originRoute);
        /* int64 originCreateTime = 6; */
        if (message.originCreateTime !== 0)
            writer.tag(6, WireType.Varint).int64(message.originCreateTime);
        /* string mainImg = 7; */
        if (message.mainImg !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mainImg);
        /* string richText = 8; */
        if (message.richText !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.richText);
        /* bpt.hall.news.v1.NewsCategory category = 9; */
        if (message.category !== 0)
            writer.tag(9, WireType.Varint).int32(message.category);
        /* int64 effectiveAt = 10; */
        if (message.effectiveAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.effectiveAt);
        /* string operator = 11; */
        if (message.operator !== "")
            writer.tag(11, WireType.LengthDelimited).string(message.operator);
        /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"]; */
        for (let k of globalThis.Object.keys(message.i18nTitle))
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nTitle[k]).join();
        /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"]; */
        for (let k of globalThis.Object.keys(message.i18nRichTextUrl))
            writer.tag(17, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nRichTextUrl[k]).join();
        /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18; */
        for (let i = 0; i < message.mainVideos.length; i++)
            MainVideosStruct.internalBinaryWrite(message.mainVideos[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated string coverImages = 19; */
        for (let i = 0; i < message.coverImages.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.coverImages[i]);
        /* string channelID = 20; */
        if (message.channelID !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.channelID);
        /* string abstract = 21; */
        if (message.abstract !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.abstract);
        /* string jumpLink = 22; */
        if (message.jumpLink !== "")
            writer.tag(22, WireType.LengthDelimited).string(message.jumpLink);
        /* string pcBannerImage = 23; */
        if (message.pcBannerImage !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.pcBannerImage);
        /* string mobileBannerImage = 24; */
        if (message.mobileBannerImage !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.mobileBannerImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.EditNewsReq
 */
export const EditNewsReq = new EditNewsReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SimpleNews$Type extends MessageType<SimpleNews> {
    constructor() {
        super("bpt.hall.news.v1.SimpleNews", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "originRoute", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "originCreateTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "mainImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "status", kind: "enum", T: () => ["bpt.hall.news.v1.NewsStatus", NewsStatus] },
            { no: 10, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "updatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 13, name: "effectiveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "i18nTitle", kind: "scalar", localName: "i18nTitle", jsonName: "i18nTitle", T: 9 /*ScalarType.STRING*/ },
            { no: 15, name: "i18nRichTextUrl", kind: "scalar", localName: "i18nRichTextUrl", jsonName: "i18nRichTextUrl", T: 9 /*ScalarType.STRING*/ },
            { no: 16, name: "topAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 17, name: "mainVideos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MainVideosStruct },
            { no: 18, name: "coverImages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 19, name: "commentTopicId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 20, name: "abstract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "jumpLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "pcBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "mobileBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<SimpleNews>): SimpleNews {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.title = "";
        message.subTitle = "";
        message.origin = "";
        message.originRoute = "";
        message.originCreateTime = 0;
        message.mainImg = "";
        message.status = 0;
        message.createdAt = 0;
        message.updatedAt = 0;
        message.category = 0;
        message.effectiveAt = 0;
        message.i18nTitle = "";
        message.i18nRichTextUrl = "";
        message.topAt = 0;
        message.mainVideos = [];
        message.coverImages = [];
        message.commentTopicId = 0;
        message.abstract = "";
        message.jumpLink = "";
        message.pcBannerImage = "";
        message.mobileBannerImage = "";
        if (value !== undefined)
            reflectionMergePartial<SimpleNews>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SimpleNews): SimpleNews {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subTitle */ 3:
                    message.subTitle = reader.string();
                    break;
                case /* string origin */ 4:
                    message.origin = reader.string();
                    break;
                case /* string originRoute */ 5:
                    message.originRoute = reader.string();
                    break;
                case /* int64 originCreateTime */ 6:
                    message.originCreateTime = reader.int64().toNumber();
                    break;
                case /* string mainImg */ 7:
                    message.mainImg = reader.string();
                    break;
                case /* bpt.hall.news.v1.NewsStatus status */ 9:
                    message.status = reader.int32();
                    break;
                case /* int64 createdAt */ 10:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 updatedAt */ 11:
                    message.updatedAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 12:
                    message.category = reader.int32();
                    break;
                case /* int64 effectiveAt */ 13:
                    message.effectiveAt = reader.int64().toNumber();
                    break;
                case /* string i18nTitle = 14 [json_name = "i18nTitle"];*/ 14:
                    message.i18nTitle = reader.string();
                    break;
                case /* string i18nRichTextUrl = 15 [json_name = "i18nRichTextUrl"];*/ 15:
                    message.i18nRichTextUrl = reader.string();
                    break;
                case /* int64 topAt */ 16:
                    message.topAt = reader.int64().toNumber();
                    break;
                case /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos */ 17:
                    message.mainVideos.push(MainVideosStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string coverImages */ 18:
                    message.coverImages.push(reader.string());
                    break;
                case /* int64 commentTopicId */ 19:
                    message.commentTopicId = reader.int64().toNumber();
                    break;
                case /* string abstract */ 20:
                    message.abstract = reader.string();
                    break;
                case /* string jumpLink */ 21:
                    message.jumpLink = reader.string();
                    break;
                case /* string pcBannerImage */ 23:
                    message.pcBannerImage = reader.string();
                    break;
                case /* string mobileBannerImage */ 24:
                    message.mobileBannerImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SimpleNews, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subTitle = 3; */
        if (message.subTitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subTitle);
        /* string origin = 4; */
        if (message.origin !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.origin);
        /* string originRoute = 5; */
        if (message.originRoute !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.originRoute);
        /* int64 originCreateTime = 6; */
        if (message.originCreateTime !== 0)
            writer.tag(6, WireType.Varint).int64(message.originCreateTime);
        /* string mainImg = 7; */
        if (message.mainImg !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mainImg);
        /* bpt.hall.news.v1.NewsStatus status = 9; */
        if (message.status !== 0)
            writer.tag(9, WireType.Varint).int32(message.status);
        /* int64 createdAt = 10; */
        if (message.createdAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.createdAt);
        /* int64 updatedAt = 11; */
        if (message.updatedAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.updatedAt);
        /* bpt.hall.news.v1.NewsCategory category = 12; */
        if (message.category !== 0)
            writer.tag(12, WireType.Varint).int32(message.category);
        /* int64 effectiveAt = 13; */
        if (message.effectiveAt !== 0)
            writer.tag(13, WireType.Varint).int64(message.effectiveAt);
        /* string i18nTitle = 14 [json_name = "i18nTitle"]; */
        if (message.i18nTitle !== "")
            writer.tag(14, WireType.LengthDelimited).string(message.i18nTitle);
        /* string i18nRichTextUrl = 15 [json_name = "i18nRichTextUrl"]; */
        if (message.i18nRichTextUrl !== "")
            writer.tag(15, WireType.LengthDelimited).string(message.i18nRichTextUrl);
        /* int64 topAt = 16; */
        if (message.topAt !== 0)
            writer.tag(16, WireType.Varint).int64(message.topAt);
        /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 17; */
        for (let i = 0; i < message.mainVideos.length; i++)
            MainVideosStruct.internalBinaryWrite(message.mainVideos[i], writer.tag(17, WireType.LengthDelimited).fork(), options).join();
        /* repeated string coverImages = 18; */
        for (let i = 0; i < message.coverImages.length; i++)
            writer.tag(18, WireType.LengthDelimited).string(message.coverImages[i]);
        /* int64 commentTopicId = 19; */
        if (message.commentTopicId !== 0)
            writer.tag(19, WireType.Varint).int64(message.commentTopicId);
        /* string abstract = 20; */
        if (message.abstract !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.abstract);
        /* string jumpLink = 21; */
        if (message.jumpLink !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.jumpLink);
        /* string pcBannerImage = 23; */
        if (message.pcBannerImage !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.pcBannerImage);
        /* string mobileBannerImage = 24; */
        if (message.mobileBannerImage !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.mobileBannerImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.SimpleNews
 */
export const SimpleNews = new SimpleNews$Type();
// @generated message type with reflection information, may provide speed optimized methods
class News$Type extends MessageType<News> {
    constructor() {
        super("bpt.hall.news.v1.News", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "title", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "subTitle", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "origin", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "originRoute", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "originCreateTime", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "mainImg", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "richText", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "status", kind: "enum", T: () => ["bpt.hall.news.v1.NewsStatus", NewsStatus] },
            { no: 10, name: "createdAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 11, name: "updatedAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 12, name: "category", kind: "enum", T: () => ["bpt.hall.news.v1.NewsCategory", NewsCategory] },
            { no: 13, name: "effectiveAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 14, name: "commentTopicId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 15, name: "topAt", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 16, name: "i18nTitle", kind: "map", localName: "i18nTitle", jsonName: "i18nTitle", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 17, name: "i18nRichTextUrl", kind: "map", localName: "i18nRichTextUrl", jsonName: "i18nRichTextUrl", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } },
            { no: 18, name: "mainVideos", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => MainVideosStruct },
            { no: 19, name: "coverImages", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 20, name: "abstract", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 21, name: "jumpLink", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 23, name: "pcBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 24, name: "mobileBannerImage", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<News>): News {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.title = "";
        message.subTitle = "";
        message.origin = "";
        message.originRoute = "";
        message.originCreateTime = 0;
        message.mainImg = "";
        message.richText = "";
        message.status = 0;
        message.createdAt = 0;
        message.updatedAt = 0;
        message.category = 0;
        message.effectiveAt = 0;
        message.commentTopicId = 0;
        message.topAt = 0;
        message.i18nTitle = {};
        message.i18nRichTextUrl = {};
        message.mainVideos = [];
        message.coverImages = [];
        message.abstract = "";
        message.jumpLink = "";
        message.pcBannerImage = "";
        message.mobileBannerImage = "";
        if (value !== undefined)
            reflectionMergePartial<News>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: News): News {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string title */ 2:
                    message.title = reader.string();
                    break;
                case /* string subTitle */ 3:
                    message.subTitle = reader.string();
                    break;
                case /* string origin */ 4:
                    message.origin = reader.string();
                    break;
                case /* string originRoute */ 5:
                    message.originRoute = reader.string();
                    break;
                case /* int64 originCreateTime */ 6:
                    message.originCreateTime = reader.int64().toNumber();
                    break;
                case /* string mainImg */ 7:
                    message.mainImg = reader.string();
                    break;
                case /* string richText */ 8:
                    message.richText = reader.string();
                    break;
                case /* bpt.hall.news.v1.NewsStatus status */ 9:
                    message.status = reader.int32();
                    break;
                case /* int64 createdAt */ 10:
                    message.createdAt = reader.int64().toNumber();
                    break;
                case /* int64 updatedAt */ 11:
                    message.updatedAt = reader.int64().toNumber();
                    break;
                case /* bpt.hall.news.v1.NewsCategory category */ 12:
                    message.category = reader.int32();
                    break;
                case /* int64 effectiveAt */ 13:
                    message.effectiveAt = reader.int64().toNumber();
                    break;
                case /* int64 commentTopicId */ 14:
                    message.commentTopicId = reader.int64().toNumber();
                    break;
                case /* int64 topAt */ 15:
                    message.topAt = reader.int64().toNumber();
                    break;
                case /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"];*/ 16:
                    this.binaryReadMap16(message.i18nTitle, reader, options);
                    break;
                case /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"];*/ 17:
                    this.binaryReadMap17(message.i18nRichTextUrl, reader, options);
                    break;
                case /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos */ 18:
                    message.mainVideos.push(MainVideosStruct.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string coverImages */ 19:
                    message.coverImages.push(reader.string());
                    break;
                case /* string abstract */ 20:
                    message.abstract = reader.string();
                    break;
                case /* string jumpLink */ 21:
                    message.jumpLink = reader.string();
                    break;
                case /* string pcBannerImage */ 23:
                    message.pcBannerImage = reader.string();
                    break;
                case /* string mobileBannerImage */ 24:
                    message.mobileBannerImage = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap16(map: News["i18nTitle"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof News["i18nTitle"] | undefined, val: News["i18nTitle"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.News.i18nTitle");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    private binaryReadMap17(map: News["i18nRichTextUrl"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof News["i18nRichTextUrl"] | undefined, val: News["i18nRichTextUrl"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field bpt.hall.news.v1.News.i18nRichTextUrl");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: News, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string title = 2; */
        if (message.title !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.title);
        /* string subTitle = 3; */
        if (message.subTitle !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.subTitle);
        /* string origin = 4; */
        if (message.origin !== "")
            writer.tag(4, WireType.LengthDelimited).string(message.origin);
        /* string originRoute = 5; */
        if (message.originRoute !== "")
            writer.tag(5, WireType.LengthDelimited).string(message.originRoute);
        /* int64 originCreateTime = 6; */
        if (message.originCreateTime !== 0)
            writer.tag(6, WireType.Varint).int64(message.originCreateTime);
        /* string mainImg = 7; */
        if (message.mainImg !== "")
            writer.tag(7, WireType.LengthDelimited).string(message.mainImg);
        /* string richText = 8; */
        if (message.richText !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.richText);
        /* bpt.hall.news.v1.NewsStatus status = 9; */
        if (message.status !== 0)
            writer.tag(9, WireType.Varint).int32(message.status);
        /* int64 createdAt = 10; */
        if (message.createdAt !== 0)
            writer.tag(10, WireType.Varint).int64(message.createdAt);
        /* int64 updatedAt = 11; */
        if (message.updatedAt !== 0)
            writer.tag(11, WireType.Varint).int64(message.updatedAt);
        /* bpt.hall.news.v1.NewsCategory category = 12; */
        if (message.category !== 0)
            writer.tag(12, WireType.Varint).int32(message.category);
        /* int64 effectiveAt = 13; */
        if (message.effectiveAt !== 0)
            writer.tag(13, WireType.Varint).int64(message.effectiveAt);
        /* int64 commentTopicId = 14; */
        if (message.commentTopicId !== 0)
            writer.tag(14, WireType.Varint).int64(message.commentTopicId);
        /* int64 topAt = 15; */
        if (message.topAt !== 0)
            writer.tag(15, WireType.Varint).int64(message.topAt);
        /* map<string, string> i18nTitle = 16 [json_name = "i18nTitle"]; */
        for (let k of globalThis.Object.keys(message.i18nTitle))
            writer.tag(16, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nTitle[k]).join();
        /* map<string, string> i18nRichTextUrl = 17 [json_name = "i18nRichTextUrl"]; */
        for (let k of globalThis.Object.keys(message.i18nRichTextUrl))
            writer.tag(17, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.i18nRichTextUrl[k]).join();
        /* repeated bpt.hall.news.v1.MainVideosStruct mainVideos = 18; */
        for (let i = 0; i < message.mainVideos.length; i++)
            MainVideosStruct.internalBinaryWrite(message.mainVideos[i], writer.tag(18, WireType.LengthDelimited).fork(), options).join();
        /* repeated string coverImages = 19; */
        for (let i = 0; i < message.coverImages.length; i++)
            writer.tag(19, WireType.LengthDelimited).string(message.coverImages[i]);
        /* string abstract = 20; */
        if (message.abstract !== "")
            writer.tag(20, WireType.LengthDelimited).string(message.abstract);
        /* string jumpLink = 21; */
        if (message.jumpLink !== "")
            writer.tag(21, WireType.LengthDelimited).string(message.jumpLink);
        /* string pcBannerImage = 23; */
        if (message.pcBannerImage !== "")
            writer.tag(23, WireType.LengthDelimited).string(message.pcBannerImage);
        /* string mobileBannerImage = 24; */
        if (message.mobileBannerImage !== "")
            writer.tag(24, WireType.LengthDelimited).string(message.mobileBannerImage);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.News
 */
export const News = new News$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableNewsCommentReq$Type extends MessageType<EnableNewsCommentReq> {
    constructor() {
        super("bpt.hall.news.v1.EnableNewsCommentReq", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<EnableNewsCommentReq>): EnableNewsCommentReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<EnableNewsCommentReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableNewsCommentReq): EnableNewsCommentReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string channelID */ 2:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnableNewsCommentReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string channelID = 2; */
        if (message.channelID !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.EnableNewsCommentReq
 */
export const EnableNewsCommentReq = new EnableNewsCommentReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EnableNewsCommentResp$Type extends MessageType<EnableNewsCommentResp> {
    constructor() {
        super("bpt.hall.news.v1.EnableNewsCommentResp", [
            { no: 1, name: "commentTopicId", kind: "scalar", T: 3 /*ScalarType.INT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<EnableNewsCommentResp>): EnableNewsCommentResp {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.commentTopicId = 0;
        if (value !== undefined)
            reflectionMergePartial<EnableNewsCommentResp>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EnableNewsCommentResp): EnableNewsCommentResp {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* int64 commentTopicId */ 1:
                    message.commentTopicId = reader.int64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EnableNewsCommentResp, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* int64 commentTopicId = 1; */
        if (message.commentTopicId !== 0)
            writer.tag(1, WireType.Varint).int64(message.commentTopicId);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.EnableNewsCommentResp
 */
export const EnableNewsCommentResp = new EnableNewsCommentResp$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MarkReadStampReq$Type extends MessageType<MarkReadStampReq> {
    constructor() {
        super("bpt.hall.news.v1.MarkReadStampReq", [
            { no: 1, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MarkReadStampReq>): MarkReadStampReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<MarkReadStampReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MarkReadStampReq): MarkReadStampReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channelID */ 1:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MarkReadStampReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channelID = 1; */
        if (message.channelID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.MarkReadStampReq
 */
export const MarkReadStampReq = new MarkReadStampReq$Type();
// @generated message type with reflection information, may provide speed optimized methods
class UnReadCountReq$Type extends MessageType<UnReadCountReq> {
    constructor() {
        super("bpt.hall.news.v1.UnReadCountReq", [
            { no: 1, name: "channelID", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<UnReadCountReq>): UnReadCountReq {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.channelID = "";
        if (value !== undefined)
            reflectionMergePartial<UnReadCountReq>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: UnReadCountReq): UnReadCountReq {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string channelID */ 1:
                    message.channelID = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: UnReadCountReq, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string channelID = 1; */
        if (message.channelID !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.channelID);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message bpt.hall.news.v1.UnReadCountReq
 */
export const UnReadCountReq = new UnReadCountReq$Type();
/**
 * @generated ServiceType for protobuf service bpt.hall.news.v1.NewsService
 */
export const NewsService = new ServiceType("bpt.hall.news.v1.NewsService", [
    { name: "CreateNews", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: CreateNewsReq, O: CreateNewsResp },
    { name: "EditNews", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: EditNewsReq, O: Void },
    { name: "DeleteNews", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: DeleteNewsReq, O: Void },
    { name: "UpdateNewsStatus", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: UpdateNewsStatusReq, O: Void },
    { name: "EnableNewsComment", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: EnableNewsCommentReq, O: EnableNewsCommentResp },
    { name: "ListNews", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: ListNewsReq, O: ListNewsResp },
    { name: "GetNewsInner", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: GetNewsInnerReq, O: GetNewsInnerResp },
    { name: "TopUpNews", options: { "blocker.exportclient": 0, "blocker.auth": 1 }, I: TopUpNewsReq, O: Void },
    { name: "EffectiveListNews", options: {}, I: EffectiveListNewsReq, O: EffectiveListNewsResp },
    { name: "GetNews", options: {}, I: GetNewsReq, O: GetNewsResp },
    { name: "MarkReadStamp", options: {}, I: MarkReadStampReq, O: Void },
    { name: "UnReadCount", options: {}, I: UnReadCountReq, O: UnReadCountResp }
]);
