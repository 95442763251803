/* eslint-disable */
// @ts-nocheck
// @ts-ignore
// prettier-ignore
/*
 *  ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
 * 
 *   ┌─────────────────────────────────┐
 *   │     🚧 此文件由工具自动生成 🚧      │ 
 *   │  禁止手动修改!    请勿跨越栏杆!     │
 *   └─────────────────────────────────┘
 *
 * ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️ ⚠️
 *
 * 生成工具: @mtt/grpc-core
 * Proto文件: ./bpt/hall/news/v1/news.proto
 */

import type { PartialMessage } from '@protobuf-ts/runtime';
import type { GrpcWebOptions, GrpcWebFetchTransport } from "@mtt/grpc-core";
import { authTransport, createAuthTransport, createNoAuthTransport, GrpcServiceMgr } from '@mtt/grpc-core';

import {
  Void
} from './client/bpt/base/base'

import {
  CreateNewsReq,
  CreateNewsResp,
  EditNewsReq,
  DeleteNewsReq,
  UpdateNewsStatusReq,
  EnableNewsCommentReq,
  EnableNewsCommentResp,
  ListNewsReq,
  ListNewsResp,
  GetNewsInnerReq,
  GetNewsInnerResp,
  TopUpNewsReq,
  EffectiveListNewsReq,
  EffectiveListNewsResp,
  GetNewsReq,
  GetNewsResp,
  MarkReadStampReq,
  UnReadCountReq,
  UnReadCountResp
} from './client/bpt/hall/news/v1/news';
import { NewsServiceClient } from './client/bpt/hall/news/v1/news.client';

export class NewsServiceMgr extends GrpcServiceMgr<NewsServiceClient> {
  constructor(createAuth?: (opts?: GrpcWebOptions) => GrpcWebFetchTransport, opts?: GrpcWebOptions) {
    super(NewsServiceClient, createAuth, opts);
  }

  /**
   * 创建新闻
   */
  createNews = (payload: PartialMessage<CreateNewsReq>, options?: GrpcWebOptions): Promise<CreateNewsResp> =>
    this._client.createNews(CreateNewsReq.create(payload), options).response;

  /**
   * 更新新闻信息
   */
  editNews = (payload: PartialMessage<EditNewsReq>, options?: GrpcWebOptions): Promise<Void> =>
    this._client.editNews(EditNewsReq.create(payload), options).response;

  /**
   * 删除新闻
   */
  deleteNews = (payload: PartialMessage<DeleteNewsReq>, options?: GrpcWebOptions): Promise<Void> =>
    this._client.deleteNews(DeleteNewsReq.create(payload), options).response;

  /**
   * 发布/取消发布新闻
   */
  updateNewsStatus = (payload: PartialMessage<UpdateNewsStatusReq>, options?: GrpcWebOptions): Promise<Void> =>
    this._client.updateNewsStatus(UpdateNewsStatusReq.create(payload), options).response;

  /**
   * 开启新闻评论
   */
  enableNewsComment = (payload: PartialMessage<EnableNewsCommentReq>, options?: GrpcWebOptions): Promise<EnableNewsCommentResp> =>
    this._client.enableNewsComment(EnableNewsCommentReq.create(payload), options).response;

  /**
   * 获取新闻详情列表
   */
  listNews = (payload: PartialMessage<ListNewsReq>, options?: GrpcWebOptions): Promise<ListNewsResp> =>
    this._client.listNews(ListNewsReq.create(payload), options).response;

  /**
   * 获取新闻详情
   */
  getNewsInner = (payload: PartialMessage<GetNewsInnerReq>, options?: GrpcWebOptions): Promise<GetNewsInnerResp> =>
    this._client.getNewsInner(GetNewsInnerReq.create(payload), options).response;

  /**
   * 设置新闻置顶
   */
  topUpNews = (payload: PartialMessage<TopUpNewsReq>, options?: GrpcWebOptions): Promise<Void> =>
    this._client.topUpNews(TopUpNewsReq.create(payload), options).response;

  /**
   * 获取新闻列表
   */
  effectiveListNews = (payload: PartialMessage<EffectiveListNewsReq>, options?: GrpcWebOptions): Promise<EffectiveListNewsResp> =>
    this._client.effectiveListNews(EffectiveListNewsReq.create(payload), options).response;

  /**
   * 获取新闻详情
   */
  getNews = (payload: PartialMessage<GetNewsReq>, options?: GrpcWebOptions): Promise<GetNewsResp> =>
    this._client.getNews(GetNewsReq.create(payload), options).response;

  /**
   * 标记已读
   */
  markReadStamp = (payload: PartialMessage<MarkReadStampReq>, options?: GrpcWebOptions): Promise<Void> =>
    this._client.markReadStamp(MarkReadStampReq.create(payload), options).response;

  /**
   * 获取未读消息数量
   */
  unReadCount = (payload: PartialMessage<UnReadCountReq>, options?: GrpcWebOptions): Promise<UnReadCountResp> =>
    this._client.unReadCount(UnReadCountReq.create(payload), options).response;

}

export const newsServiceMgr = new NewsServiceMgr(createAuthTransport);

/** @deprecated 请使用 newsServiceMgrNoAuth */
export const newsServiceMgrRaw = new NewsServiceMgr(() => authTransport);

/**
 * 如果默认的 noauth 不满足需求，可以通过 _setBaseUrlAddon —— newsServiceMgrNoAuth._setBaseUrlAddon('/xxx_noauth')
 */
export const newsServiceMgrNoAuth = new NewsServiceMgr(createNoAuthTransport, { baseUrlAddon: '/news_noauth' });
