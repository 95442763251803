module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@3.0.1_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__bufferutil_7sdwxnkaniefblzvecmh5yrivu/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSource":"./src/locales","languages":["en"],"defaultLanguage":"en","redirect":true,"i18nextOptions":{"fallbackLng":"en","debug":true,"interpolation":{"escapeValue":false}}},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-smoothscroll@1.2.0/node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0_6hlznhif6k5iecxloyuqwz4v3m/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"MTT Network","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4d651d63f137071c0575a5eda5d4cbcd"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.14.0_babel-eslint@10.1.0_eslint@7.32.0__bufferutil@4.0.8_react-dom@18.3.1_react@18.3_4vtwlxjiqoguuh7he2vhfd7odq/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
